import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Platform, View } from "react-native";

import { WherebyEmbed, type WherebyWebView } from "@whereby.com/react-native-sdk/embed";
import { Audio } from "expo-av";
import { Camera } from "expo-camera";
import { LinearGradient } from "expo-linear-gradient";
import { ActivityIndicator } from "react-native-paper";
import { VideoChatService } from "../../../services/videochat/videochat.service";
import { colors } from "../../constants/colors";
import { RouteNames } from "../../constants/routeNames";
import { AuthContext } from "../../provider/AuthProvider";

export default function VideoCall({ navigation, route }: any) {
  const { userName, roomName, meetingLink, meetingId } = route.params;
  const { userData } = useContext(AuthContext);
  const [roomUrl, setURL] = useState<string>("");
  const [meetingID, setMeetingID] = useState<string>("");

  const videChatService = new VideoChatService();

  const handleLeave = async () => {
    if (meetingLink) {
      // do nothing as we want to keep this room
      return;
    }
    videChatService.deleteVideoCallRoom(meetingID);
    await navigation.reset({
      index: 0,
      routes: [
        {
          name: RouteNames.CALL_FEEDBACK,
          params: { roomName: roomName, userName: userName },
        },
      ],
    });
  }

  useEffect(() => {
    const getRoomURL = async () => {
      if (meetingLink) {
        setURL(meetingLink);
        setMeetingID(meetingId);
        return;
      }
      const res = await videChatService.getVideoCallRoom({ roomMode: "group", roomPrefix: `${userData?.id}` as string });
      if (res.data && res.data.data && res.data.data.roomUrl) {
        setURL(res.data.data.roomUrl);
        setMeetingID(res.data.data.meetingId);
      } else {
        console.log('Failed to get room');
      }
    };
    getRoomURL().then((res) => {

    });
  }, []);

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={["rgba(42, 46, 53, 1)", "rgba(42, 46, 53, 1)"]}
      style={{
        flex: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <Fragment>
        {roomUrl == "" ? (
          <View
            style={{
              backgroundColor: "rgba(42, 46, 53, 1)",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              width: "100%",
              height: "100%",
            }}
          >
            <ActivityIndicator size="large" color={colors.primaryColor} />
          </View>
        ) : (
          <>
            <WhereByCall roomUrl={roomUrl} onLeaveHandler={handleLeave} />
          </>
        )}
      </Fragment>
    </LinearGradient>
  );
}

export interface PhyxableVideoCallProps {
  user_id: string;
  room_id: string;
}


function WhereByCall({ roomUrl, onLeaveHandler }: { roomUrl: string, onLeaveHandler: any }) {
  if (Platform.OS === 'web') {
    const WhereByCallWeb = require('./WhereByCallWeb').default;
    return <WhereByCallWeb roomUrl={roomUrl} onLeaveHandler={onLeaveHandler} />;

  } else {
    const ROOM_URL = roomUrl;
    const wherebyRoomRef = React.useRef<WherebyWebView>(null);
    const [hasPermissionForAndroid, setHasPermissionForAndroid] = React.useState<boolean>(false);

    React.useEffect(() => {
      (async () => {
        if (Platform.OS === "android") {
          const cameraStatus = await Camera.requestCameraPermissionsAsync();
          const audioStatus = await Audio.requestPermissionsAsync();
          if (cameraStatus.status === "granted" && audioStatus.status === "granted") {
            setHasPermissionForAndroid(true);
          } else {
            Alert.alert("Permissions Required", "Camera and microphone permissions are required.");
            setHasPermissionForAndroid(false);
          }
        }
      })();
    }, []);

    if (Platform.OS === "android" && !hasPermissionForAndroid) {
      return <View />;
    }

    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, height: "100%" }}>
          <WherebyEmbed
            ref={wherebyRoomRef}
            style={{ flex: 1 }}
            room={ROOM_URL}
            // Removes some UI elements. Useful for small screens.
            // minimal <-- This removes the leave button
            // Skips the media permission prompt.
            skipMediaPermissionPrompt
            // Catch-all for any Whereby event
            onWherebyMessage={(event) => {
              console.log("Whereby event", event);
            }}
            // Specific callbacks for each Whereby event
            onReady={() => {
              console.log("Whereby is ready");

            }}
            onLeave={() => {
              onLeaveHandler();
            }}
          />
        </View>
      </View>
    );
  }
};
