import { useEffect, useMemo, useState } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { DataTable } from "react-native-paper";
import { colors } from "../../constants/colors";
import { IProgress } from "../../provider/AuthProvider";
import { formatXAsixDates, sortDataByDate } from "../helper";
import { IWorkoutData } from "./ProgressChart";

const ITEMS_PER_PAGE = 10;

type Props = {
  progress: IProgress;
  customPhyxId: string;
  isWeb?: boolean;
};

export default function CustomProgressChart({
  progress,
  customPhyxId,
  isWeb = true,
}: Props) {
  const [workoutData, setWorkoutData] = useState<IWorkoutData>({});
  const [workoutList, setWorkoutList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0); // starting with 0
  const [error, setError] = useState(null);
  const { width, height } = useWindowDimensions();

  const createDataArray = () => {
    const difficultyMap = {
      "0": "Just Right",
      "-1": "Challenging",
      "1": "Easy",
    };

    const value = progress.customPhyx || {};
    let _workoutData = {};
    const _workoutList = [];

    try {
      for (const [key2, value2] of Object.entries(value)) {
        // key2: customPhyx id
        const workoutProgram = [];
        const tmp = [];
        const dates = [];
        const difficulty = [];
        const session = [];
        const level = [];

        if (value2["progress"] != undefined)
          for (const [key3, value3] of Object.entries(value2["progress"])) // key3: level
            for (let i2 = 0; i2 < value3.length; i2++) {
              value3[i2]["outcome_forms"]?.forEach((outcomeForm) => {
                // pull PSFS  data for PSFS chart AND pull non PSFS data for non-PSFS chart (but don't pull both data for both types of charts!)
                if (outcomeForm.type !== "PSFS") {
                  dates.push(value3[i2].date);
                  tmp.push(outcomeForm.answer);
                  difficulty.push(
                    value3[i2].difficulty !== undefined
                      ? difficultyMap[value3[i2].difficulty.toString()]
                      : "No Difficulty",
                  );
                  session.push("No session");
                  level.push(parseInt(key3) + 1);
                }
              });

              // if no outcome forms let's just mark one data point!
              if (!value3[i2]["outcome_forms"]?.length) {
                dates.push(value3[i2].date);
                tmp.push(0);
                difficulty.push(
                  value3[i2].difficulty !== undefined
                    ? difficultyMap[value3[i2].difficulty.toString()]
                    : "No Difficulty",
                );
                session.push("No session");
                level.push(parseInt(key3) + 1);
              }
            }

        workoutProgram[0] = tmp;
        workoutProgram[1] = dates;
        workoutProgram[2] = difficulty;
        workoutProgram[3] = session;
        workoutProgram[4] = level;

        // as long as there is one point of data let's add it
        if (tmp.length > 0) {
          _workoutData = { ..._workoutData, [key2]: workoutProgram };
          _workoutList.push({
            value: workoutProgram[0],
            text: workoutProgram[0],
          });
        }
      }

      if (_workoutList.length > 0) {
        // console.log(12345, workoutData);
        // if (workoutData[0].length > 0 && workoutData[0][2].length > 0)
        //   this.startingPnt = workoutData[0][2].reduce(function (a, b) {
        //     return a < b ? a : b;
        //   });
        // workoutData[0][7] = [this.startingPnt, 0, 0, 0];
      } else {
        _workoutList.push({ value: "No Data", text: "No Data" });
      }

      // let's add the smallest date to each
      // for (const workoutArr of _workoutData) {
      //   const lowestDate =
      //     workoutArr[2].length > 0
      //       ? workoutArr[2].reduce(function (a, b) {
      //           return a < b ? a : b;
      //         })
      //       : new Date();
      //   workoutArr[7] = [lowestDate, 0, 0, 0];
      // }

      console.log("CustomProgressChart WORKOUT", _workoutData);
    } catch (error: any) {
      setError(error);
      console.log("CustomProgressChart error: ", error);
    }

    setWorkoutData(_workoutData);
    setWorkoutList(_workoutList);
  };

  useEffect(() => {
    createDataArray();
  }, [progress]);

  const totalPages = useMemo(() => {
    const totalItems = workoutData[customPhyxId]?.[0]?.length || 0;
    return Math.ceil(totalItems / ITEMS_PER_PAGE);
  }, [workoutData, customPhyxId]);

  const customGraphData = useMemo(() => {
    if (
      !Object.keys(workoutData).length ||
      !customPhyxId ||
      !workoutData[customPhyxId]
    ) {
      return null;
    }

    const progressData = sortDataByDate(workoutData[customPhyxId], 1);

    if (!progressData || !progressData[1].length) {
      return null;
    }

    const labels = progressData[1].map((date: string) =>
      new Date(date).toLocaleDateString(),
    );
    const data = progressData[0];
    if (labels.length === 1) {
      labels.unshift("0/0/0");
      data.unshift(0);
    }

    // Pagination logic: slice data based on current page
    const totalItems = data.length;
    const shift = totalPages * ITEMS_PER_PAGE - totalItems;
    const startIndex = Math.max(currentPage * ITEMS_PER_PAGE - shift, 0);
    const endIndex =
      startIndex === 0 ? ITEMS_PER_PAGE - shift : startIndex + ITEMS_PER_PAGE;

    return {
      labels: formatXAsixDates(labels.slice(startIndex, endIndex)),
      datasets: [
        {
          data: data.slice(startIndex, endIndex),
        },
      ],
    };
  }, [workoutData, customPhyxId, currentPage]);

  // Set the default page to the last page when workout data changes
  useEffect(() => {
    if (totalPages >= 1) {
      // Set currentPage to last page if it exists
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages]);

  return customGraphData ? (
    <View style={styles.container}>
      <LineChart
        data={customGraphData}
        width={width * (isWeb ? 0.35 : 1)}
        height={height * (isWeb ? 0.5 : 0.2)}
        yAxisInterval={1}
        fromZero={true}
        chartConfig={{
          backgroundGradientFrom: "white",
          backgroundGradientTo: "white",
          decimalPlaces: 0,
          color: (opacity = 1) => colors.primaryColor,
          labelColor: (opacity = 1) => colors.secondaryColor,
          strokeWidth: 3,
          useShadowColorFromDataset: false,
          fillShadowGradient: colors.primaryColor,
          propsForBackgroundLines: {
            strokeWidth: 1, // Line thickness
            stroke: "#e0e0e0", // Line color (light gray)
            strokeDasharray: "0", // Solid lines
          },
        }}
        bezier
        style={{
          alignSelf: "flex-start",
        }}
      />
      <DataTable.Pagination
        page={currentPage}
        numberOfPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
        label={`Page ${currentPage + 1} of ${totalPages}`}
        showFastPaginationControls={false}
        numberOfItemsPerPage={ITEMS_PER_PAGE}
      />
    </View>
  ) : error ? (
    <Text>{error.message || error.toString()}</Text>
  ) : (
    <Text style={styles.emptyChart}>No data to display right now</Text>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  emptyChart: {
    marginLeft: 40,
    marginTop: 20,
    color: colors.primaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
});
