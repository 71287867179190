import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import * as WebBrowser from "expo-web-browser";
import { Alert, Platform } from "react-native";
export const getSubscriptions = async (
  userData: any,
  paymentService: any,
  setMonthlySubscriptions: any,
  setYearlySubscriptions: any,
  setPlansInView: any,
  setActiveSubscription: any,
  setAllCompanies: any,
) => {
  const [responseSubscription, responseCompanyCodes] = await Promise.all([
    paymentService.getAvailableSubscriptions(userData?.id as string),
    paymentService.getAllCompanyCodes(),
  ]);
  if (responseSubscription.data) {
    const [_monthlySubscriptions] = responseSubscription.data.filter(
      (item: any) => item.type === "Monthly",
    );
    const [_yearlySubscriptions] = responseSubscription.data.filter(
      (item: any) => item.type === "Yearly",
    );
    let [_activeSubscription] = responseSubscription.data.filter((item: any) =>
      item.plans.some((plan: any) => {
        if (plan.current === true) {
          return plan;
        }
      }),
    );
    if (_activeSubscription) {
      _activeSubscription = _activeSubscription.plans.filter(
        (plan: any) => plan.current === true,
      )[0];
    }

    setAllCompanies(responseCompanyCodes.data);
    setMonthlySubscriptions(_monthlySubscriptions);
    setYearlySubscriptions(_yearlySubscriptions);
    setPlansInView(_monthlySubscriptions);
    setActiveSubscription(_activeSubscription);
  }
};

export const GetFileNameFromURL = (url: string) => {
  const split = url.split("/");
  if (split?.length === 0) return null;
  const chatRoom = split[split.length - 1];
  const _ = chatRoom?.split("_")[1]?.split("_")[0] || null;
  // remove the %  and date from the file name
  if (!_ || _?.length === 0) return null;
  return _.replace(/%/g, " ").replace(/_/g, " ");
};

export const handleFileDownload = async (message: any) => {
  try {
    const { data, name } = message;
    if (Platform.OS === "web") {
      const response = await fetch(data);
      if (response.status === 200) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
      }
    } else {
      // download file
      const result = await FileSystem.downloadAsync(
        data,
        FileSystem.documentDirectory + name,
      );
      const uri = result.uri;
      if (uri) {
        //save file
        await saveFile(uri, name, result.mimeType || getMimeType(name));
        Alert.alert(
          "File downloaded successfully",
          `${name} downloaded successfully`,
          [
            { text: "OK", onPress: () => {} },
            {
              text: "View",
              onPress: () => {
                FileSystem.getContentUriAsync(uri).then((contentUri) => {
                  console.log("contentUri", contentUri);
                  WebBrowser.openBrowserAsync(data, { showTitle: true });
                });
              },
            },
          ],
        );
      }
    }
  } catch (error) {
    console.log("Error downloading file", error);
  }
};

async function saveFile(uri: string, filename: string, mimetype: string) {
  if (Platform.OS === "android") {
    const permissions =
      await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();
    if (permissions.granted) {
      const base64 = await FileSystem.readAsStringAsync(uri, {
        encoding: FileSystem.EncodingType.Base64,
      });

      await FileSystem.StorageAccessFramework.createFileAsync(
        permissions.directoryUri,
        filename,
        mimetype,
      )
        .then(async (uri) => {
          await FileSystem.writeAsStringAsync(uri, base64, {
            encoding: FileSystem.EncodingType.Base64,
          });
        })
        .catch((e) => console.log(e));
    } else {
      Sharing.shareAsync(uri);
    }
  } else if (Platform.OS === "ios") {
    Sharing.shareAsync(uri);
  }
}

const getMimeType = (filename: string) => {
  const extension = filename.split(".").pop();
  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "jpg":
      return "image/jpeg";
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "txt":
      return "text/plain";
    case "rtf":
      return "application/rtf";
    case "csv":
      return "text/csv";
    case "zip":
      return "application/zip";
    case "rar":
      return "application/x-rar-compressed";
    case "7z":
      return "application/x-7z-compressed";
    case "tar":
      return "application/x-tar";
    case "gz":
      return "application/gzip";
    case "mp3":
      return "audio/mpeg";
    case "wav":
      return "audio/wav";
    case "mp4":
      return "video/mp4";
    case "avi":
      return "video/x-msvideo";
    case "wmv":
      return "video/x-ms-wmv";
    case "flv":
      return "video/x-flv";
    case "mov":
      return "video/quicktime";
    case "mkv":
      return "video/x-matroska";
    default:
      return "application/octet-stream";
  }
};

export const sortDataByDate = (data: any[][], dateIndex: number) => {
  // Extract the second sub-array which contains the dates
  const dates: string[] = data[dateIndex];

  // Create an array of indices that will sort the dates array
  const sortedIndices = [...dates]
    .map((date, index) => index)
    .sort((a, b) => new Date(dates[a]) - new Date(dates[b]));

  // Sort each sub-array using the sortedIndices
  return data.map((subArray) => {
    return subArray.map((item, index) => subArray[sortedIndices[index]]);
  });
};

export const formatXAsixDates = (dates: string[]) => {
  // Helper function to extract month and date in the desired format
  const formatDate = (date: string) => {
    const [month, day, year] = date.split("/");
    return { month, day };
  };

  const result = [];
  let previousMonth = null;

  for (let i = 0; i < dates.length; i++) {
    const { month, day } = formatDate(dates[i]);

    // Check if we are at the first or last date
    if (i === 0 || i === dates.length - 1) {
      result.push(`${month}/${day}`);
    }
    // Check for a month change
    else if (previousMonth !== month) {
      result.push(`${month}/${day}`);
    }
    // Otherwise, just push the date as it is (only day)
    else {
      result.push(day);
    }

    previousMonth = month;
  }

  return result;
};
