import { useEffect, useMemo, useState } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { LineChart } from "react-native-chart-kit";
import { DataTable } from "react-native-paper";
import { colors } from "../../constants/colors";
import { IProgress } from "../../provider/AuthProvider";
import { formatXAsixDates, sortDataByDate } from "../helper";

export type IWorkoutData = {
  [key: string]: Array<Array<string | number>>;
};

const ITEMS_PER_PAGE = 10;

type Props = {
  progress: IProgress;
  phyxId: string;
  isWeb?: boolean;
};

export default function ProgressChart({
  progress,
  phyxId,
  isWeb = true,
}: Props) {
  const [workoutData, setWorkoutData] = useState<IWorkoutData>({});
  const [workoutList, setWorkoutList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0); // starting with 0
  const { width, height } = useWindowDimensions();
  const [error, setError] = useState(null);

  const createDataArray = () => {
    const difficultyMap = {
      "0": "Just Right",
      "-1": "Challenging",
      "1": "Easy",
    };

    let _workoutData = {};
    const _workoutList = [];

    try {
      for (const [key, v] of Object.entries(progress)) {
        const workoutProgram = [];
        const vas = [];
        const dates = [];
        const difficulty = [];
        const session = [];
        const level = [];

        if (key === "customPhyx") {
          continue;
        }

        const value = v as any;

        for (let i = 0; i < value.length; i++) {
          // level
          if (!value[i]) continue;
          for (const [key2, value2] of Object.entries(value[i])) {
            // session
            for (let i2 = 0; i2 < value2.length; i2++) {
              // count
              if (value2[i2].VAS === null) {
                continue;
              }
              vas.push(value2[i2].VAS);
              dates.push(value2[i2].date);
              difficulty.push(
                value2[i2].difficulty !== undefined
                  ? difficultyMap[value2[i2].difficulty.toString()]
                  : "No Difficulty",
              );
              session.push(parseInt(key2) + 1);
              level.push(i);
            }
          }
        }

        workoutProgram[0] = vas;
        workoutProgram[1] = dates;
        workoutProgram[2] = difficulty;
        workoutProgram[3] = session;
        workoutProgram[4] = level;
        _workoutData = { ..._workoutData, [key]: workoutProgram };
        _workoutList.push({ value: key, text: key });
      }

      if (_workoutList.length > 0) {
        // console.log(12345, workoutData);
        // if (workoutData[0].length > 0 && workoutData[0][2].length > 0)
        //   this.startingPnt = workoutData[0][2].reduce(function (a, b) {
        //     return a < b ? a : b;
        //   });
        // workoutData[0][7] = [this.startingPnt, 0, 0, 0];
      } else {
        _workoutList.push({ value: "No Data", text: "No Data" });
      }

      // let's add the smallest date to each
      // for (const workoutArr of _workoutData) {
      //   const lowestDate =
      //     workoutArr[2].length > 0
      //       ? workoutArr[2].reduce(function (a, b) {
      //           return a < b ? a : b;
      //         })
      //       : new Date();
      //   workoutArr[7] = [lowestDate, 0, 0, 0];
      // }

      console.log("ProgressChart WORKOUT", _workoutData);
    } catch (error: any) {
      setError(error);
      console.log("ProgressChart error: ", error);
    }
    setWorkoutData(_workoutData);
    setWorkoutList(_workoutList);
  };

  useEffect(() => {
    createDataArray();
  }, [progress]);

  const totalPages = useMemo(() => {
    const totalItems = workoutData[phyxId]?.[0]?.length || 0;
    return Math.ceil(totalItems / ITEMS_PER_PAGE);
  }, [workoutData, phyxId]);

  const graphData = useMemo(() => {
    if (!Object.keys(workoutData).length || !phyxId || !workoutData[phyxId]) {
      return null;
    }

    const progressData = sortDataByDate(workoutData[phyxId], 1);

    if (!progressData || !progressData[1].length) {
      return null;
    }

    const labels = progressData[1].map((date: string) =>
      new Date(date).toLocaleDateString(),
    );
    const data = progressData[0];
    if (labels.length === 1) {
      labels.unshift("0/0/0");
      data.unshift(0);
    }

    // Pagination logic: slice data based on current page
    const totalItems = data.length;
    const shift = totalPages * ITEMS_PER_PAGE - totalItems;
    const startIndex = Math.max(currentPage * ITEMS_PER_PAGE - shift, 0);
    const endIndex =
      startIndex === 0 ? ITEMS_PER_PAGE - shift : startIndex + ITEMS_PER_PAGE;

    return {
      labels: formatXAsixDates(labels.slice(startIndex, endIndex)),
      datasets: [
        {
          data: data.slice(startIndex, endIndex),
        },
      ],
    };
  }, [workoutData, phyxId, currentPage]);

  // Set the default page to the last page when workout data changes
  useEffect(() => {
    if (totalPages >= 1) {
      // Set currentPage to last page if it exists
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages]);

  return graphData ? (
    <View style={styles.container}>
      <LineChart
        data={graphData}
        width={width * (isWeb ? 0.35 : 1)}
        height={height * (isWeb ? 0.5 : 0.2)}
        yAxisInterval={1}
        fromZero={true}
        chartConfig={{
          backgroundGradientFrom: "white",
          backgroundGradientTo: "white",
          decimalPlaces: 0,
          color: (opacity = 1) => colors.primaryColor,
          labelColor: (opacity = 1) => colors.secondaryColor,
          strokeWidth: 3,
          useShadowColorFromDataset: false,
          fillShadowGradient: colors.primaryColor,
          propsForBackgroundLines: {
            strokeWidth: 1, // Line thickness
            stroke: "#e0e0e0", // Line color (light gray)
            strokeDasharray: "0", // Solid lines
          },
        }}
        bezier
        style={{
          alignSelf: "flex-start",
        }}
      />
      <DataTable.Pagination
        page={currentPage}
        numberOfPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
        label={`Page ${currentPage + 1} of ${totalPages}`}
        showFastPaginationControls={false}
        numberOfItemsPerPage={ITEMS_PER_PAGE}
      />
    </View>
  ) : error ? (
    <Text>{error.message || error.toString()}</Text>
  ) : (
    <Text style={styles.emptyChart}>No data to display right now</Text>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  emptyChart: {
    marginLeft: 40,
    marginTop: 20,
    color: colors.primaryColor,
    fontWeight: "700",
    fontSize: 16,
  },
});
